import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import React from 'react'
import { languages } from './../../../config/languages'
import { mq } from './../../../utility/breakpoint'

interface FooterProps {
  currentLanguage: string
}

export const LanguageChange: React.FC<FooterProps> = ({
  currentLanguage = 'de',
}) => {
  const [loaded, setLoaded] = React.useState(false)
  React.useEffect(() => {
    setLoaded(true)
  }, [])

  const languageChanged = (lang: string) => {
    if (typeof window !== 'undefined') {
      // feedback has other structure

      if (document.location.pathname.match(/feedback\/[a-z]{2}/)) {
        navigate(`/feedback/${lang}/${document.location.search}`)
      } else {
        navigate(
          `${document.location.pathname.replace(/\/(.{1,2})\/?/, `/${lang}/`)}${
            document.location.search
          }`
        )
      }
    }
  }

  return (
    <LanguageChangeContainer>
      {languages.languages.map(({ key }) => (
        <LanguageItem
          key={key}
          onClick={() => languageChanged(key)}
          className={currentLanguage === key ? 'active' : ''}
        >
          {key}
        </LanguageItem>
      ))}
    </LanguageChangeContainer>
  )
}

const LanguageChangeContainer = styled.ul`
  display: flex;
  list-style: none;
  text-transform: capitalize;
  margin: 0;
  padding: 0;
  height: 20px;
`

const LanguageItem = styled.li(({ theme: { colors, fonts } }) => ({
  cursor: 'pointer',
  paddingLeft: '5px',
  color: colors.linkDeactive,
  fontSize: fonts.fontSize,
  '&.active': {
    color: colors.light,
    fontFamily: fonts.fontFamilyBlack,
  },

  paddingLeft: '5px',
  paddingRight: '5px',
  ' &:not(:first-of-type)': { borderLeft: `1px solid ${colors.linkDeactive}` },
}))
