import styled from '@emotion/styled'
import React from 'react'
import { mq } from './../../../utility/breakpoint'

interface LogoProps {
  uri: string
}

export const Logo: React.FC<LogoProps> = ({
  uri = 'http://www.siemens.com',
}) => (
  <LogoHolder href={uri} target="_blank">
    <Brand
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="120"
      height="22"
      viewBox="0 0 130 22"
    >
      <path d="M8,0c1.3,0,3.2,0.3,5.6,0.8v3.9c-1.8-0.8-3.5-1.1-5.1-1.1c-2.2,0-3.3,0.6-3.3,1.9c0,0.5,0.2,0.9,0.7,1.2c0.4,0.2,1.4,0.8,3.1,1.5c2.4,1.1,4,2.1,4.8,2.9c0.9,1,1.4,2.2,1.4,3.8c0,2.2-0.9,3.9-2.8,5.1c-1.5,1-3.5,1.4-5.9,1.4c-2,0-4.1-0.2-6.1-0.8v-4.1c2.2,0.7,4.2,1.1,5.9,1.1c2.4,0,3.5-0.7,3.5-2c0-0.5-0.2-0.9-0.5-1.2C9,14.2,8.1,13.7,6.6,13C4,11.9,2.3,11,1.5,10.2C0.5,9.1,0,7.8,0,6.3c0-2,0.7-3.6,2.2-4.7C3.7,0.5,5.6,0,8,0z M122.3,0c1.3,0,3,0.2,5.2,0.7l0.5,0.1v3.9c-1.8-0.8-3.5-1.1-5.1-1.1c-2.2,0-3.3,0.6-3.3,1.9c0,0.5,0.2,0.9,0.7,1.2c0.4,0.2,1.4,0.7,3.1,1.5c2.4,1.1,4,2.1,4.8,2.9c0.9,1,1.4,2.2,1.4,3.8c0,2.2-0.9,3.9-2.8,5.1c-1.5,1-3.5,1.4-5.9,1.4c-2,0-4.1-0.2-6.1-0.8v-4.1c2.2,0.7,4.2,1.1,5.9,1.1c2.4,0,3.5-0.7,3.5-2c0-0.5-0.2-0.9-0.5-1.2c-0.4-0.4-1.3-0.8-2.7-1.5c-2.6-1.1-4.3-2.1-5.1-2.9c-1-1-1.5-2.3-1.5-3.9c0-2,0.7-3.6,2.2-4.6C118,0.5,119.9,0,122.3,0z M53.9,0.4l5,13.2L64,0.4h6.8V21h-5.2V6.4l-5.8,14.8h-3.4L50.7,6.4V21h-3.9V0.4H53.9z M23.8,0.4V21h-5.5V0.4H23.8z M43,0.4v3.7h-8.8v4.7h7.7v3.4h-7.7v4.9h9V21H28.8V0.4H43z M89.9,0.4v3.7h-8.8v4.7h7.7v3.4h-7.7v4.9h9V21H75.8V0.4H89.9z M100.2,0.4l6.8,13.8V0.4h3.9V21h-6.2l-7-14v14h-3.9V0.4H100.2z" />
    </Brand>
  </LogoHolder>
)

const LogoHolder = styled.a`
  ${mq[3]}: {
    margin-top: 40px;
  }
  grid-column: 3/7;
  z-index: 1002;
`
// Double size/scale 50% cause safari rendering bug at svg

const Brand1 = styled.svg({
  width: '240px',
  height: '41px',
  transform: 'scale(0.5)',
  transformOrigin: 'left top',
  path: {
    fill: 'red',
  },
})

const Brand = styled.svg(
  ({ theme: { colors, fonts } }) => `
  width: 240px;
  height:41px;
  transform:scale(0.5);
  transform-origin:left top;
  path{
    fill:${colors.menuItem};
  }
`
)
