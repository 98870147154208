import styled from '@emotion/styled'
import { graphql, StaticQuery } from 'gatsby'
import Link from 'gatsby-link'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const FooterMenu = ({ language }: { language: string }) => {
  const { t } = useTranslation('footer')

  return (
    <StaticQuery
      query={graphql`
        query footerMenu {
          site {
            siteMetadata {
              footerMenu {
                name
                target
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <HeaderMenuContainer>
            {data.site.siteMetadata.footerMenu.map(
              ({ name, target = '_self' }, index) => {
                return (
                  <a key={index} target={target} href={t(`${name}.url`)}>
                    {t(`${name}.label`)}
                  </a>
                )
              }
            )}
          </HeaderMenuContainer>
        )
      }}
    />
  )
}

const HeaderMenuContainer = styled.div(
  ({ theme: { colors } }) => `
  display: inline-flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  height: 100%;
  a{
    color:${colors.link};
    text-decoration:underline;
    font-size:.875rem;
    padding-right:20px;
    margin-bottom:15px;
    display:inline-block;
    white-space:nowrap;
    &:hover{
      color:${colors.linkHover};
    }
  }
`
)
