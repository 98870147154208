import flatten from 'flat'
export interface JsonTranslation {
  [name: string]: string
}
export interface JsonTranslationDeep {
  [name: string]: string | JsonTranslationDeep
}
export class I18nUtil {
  public static flattenLocales = (obj: JsonTranslationDeep): JsonTranslation =>
    flatten(obj)

  public static getLocale = (lang: string) => {
    try {
      return require(`./${lang}.json`) || {}
    } catch (e) {
      console.warn(`Could not load "./${lang}.json"`)
    }
  }
}
