import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { useFeatureFlags, useTimed } from 'cuenect-web-core'
import { graphql, StaticQuery } from 'gatsby'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MobileMenuItem } from '../../atoms/mobileMenuItem'
import { LanguageChange } from '../languageChange'
import { languages } from './../../../config/languages'
import {
  isPostEvent,
  isPostEventAppointments,
  isPostEventFirst,
  isPostStream,
} from './../../../config'
type TBool = boolean | undefined

export interface MobileMenuProps {
  visible: boolean
  language?: string
  currentLanguage: string
  hasParticipation?: boolean
}

export const MobileMenu: React.FC<MobileMenuProps> = ({
  visible,
  language,
  currentLanguage,
  hasParticipation,
}) => {
  const { t } = useTranslation('menuItems')
  const { phase2 } = useFeatureFlags()
  const phase3MenuPoints = [
    'index',
    'live',
    'agendaPhase3',
    'tech-sessions',
    'dialog',
    'informationmaterial',
  ]
  const phase3MenuPointsPostStream = [
    'index',
    'agendaPhase4',
    'tech-sessions',
    'dialog',
    'informationmaterial',
  ]
  const phase4MenuPoints = [
    'index',
    'agendaPhase4',
    'dialog',
    'informationmaterial',
  ]

  const [subMenuSelected, setSubMenuSelected] = useState(undefined as TBool)

  const selectedClass = (s: TBool): string => {
    return s !== undefined ? (s ? 'selected' : 'unselected') : ''
  }

  return (
    <MobileMenuContainer visible={visible} currentLanguage={language || 'en'}>
      <StaticQuery
        query={graphql`
          query MobileMenu {
            site {
              siteMetadata {
                mainMenu {
                  active
                  name
                  home
                  link
                }
              }
            }
          }
        `}
        render={data => {
          const navData = [
            ...data.site.siteMetadata.mainMenu.filter(
              ({ active }: { active: boolean }) => active
            ),
          ].filter(({ hidePostEvent }) =>
            isPostEvent && hidePostEvent && isPostEvent() ? false : true
          )

          return (
            <MobileMenuHolder className={selectedClass(subMenuSelected)}>
              {navData.map(({ name, link, home, subitems }, index) => {
                if (isPostEvent() && !phase4MenuPoints.includes(name)) {
                  return null
                }
                if (
                  !isPostEvent() &&
                  !isPostStream() &&
                  !phase3MenuPoints.includes(name)
                ) {
                  return null
                }

                if (
                  !isPostEvent() &&
                  isPostStream() &&
                  !phase3MenuPointsPostStream.includes(name)
                ) {
                  return null
                }

                return (
                  <MobileMenuItem
                    to={link}
                    key={index}
                    home={home}
                    language={language}
                    subitems={subitems}
                    onSubmenu={(selected: boolean) =>
                      setSubMenuSelected(selected)
                    }
                  >
                    {t(`${name}`)}
                  </MobileMenuItem>
                )
              })}
            </MobileMenuHolder>
          )
        }}
      />

      <LanguageChangeContainer>
        {languages.languages.length > 1 && (
          <LanguageChange currentLanguage={currentLanguage} />
        )}
      </LanguageChangeContainer>
    </MobileMenuContainer>
  )
}

const MobileMenuContainer = styled.div<MobileMenuProps>(
  ({ theme: { grid }, visible }) => ({
    background:
      'linear-gradient(180deg, #000028 0%, #001034 100px, #001034 100%);',
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    display: visible ? 'grid' : 'none',
    gridTemplateColumns: `repeat(${grid.columns},1fr)`,
    gridAutoRows: '1fr',
    marginTop: '-40px',
    paddingTop: '90px',
  })
)

const MobileMenuHolder = styled.div({
  marginTop: '80px',
  width: '100%',
  height: 'calc(100vh - 140px)',
  overflowY: 'auto',
  gridColumn: '3/35',
  '&.selected': {
    transform: 'translateX(-100vw)',
    animation: `${keyframes`
        0% { transform: translateX(0) }
        100% { transform: translateX(-100vw) }
    `} 0.25s `,
  },
  '&.unselected': {
    transform: 'translateX(0)',
    animation: `${keyframes`
        0% { transform: translateX(-100vw) }
        100% { transform: translateX(0) }
    `} 0.25s `,
  },
})

const LanguageChangeContainer = styled.div({
  position: 'absolute',
  right: '20px',
  bottom: '80px',
})
