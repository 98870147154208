import styled from '@emotion/styled'

import Link from 'gatsby-link'
import React, { useState } from 'react'
import { SieArrow } from '../icons'
import { MenuItemProps } from './../menuItem'

import { useTranslation } from 'react-i18next'
import { Text } from '..'

export function MobileMenuItem<T>({
  children,
  ref,
  ...rest
}: MenuItemProps<T>) {
  const { to, language, subitems, onSubmenu = () => null } = rest

  const sublinksActive = subitems
    ? subitems.filter(({ active }: { active: boolean }) => active)
    : null
  const isSublinkAvalable = sublinksActive && sublinksActive.length > 0

  const linkto = isSublinkAvalable ? '#' : `/${language || 'en'}${to}`

  const [subMenuVisible, setSubMenuVisible] = useState(false)

  const subMenuSelected = (visible: boolean) => {
    setSubMenuVisible(visible)
    onSubmenu(visible)
  }

  const { t } = useTranslation('menuItems')

  return (
    <StyledListItem>
      <Link
        to={linkto}
        activeClassName="active"
        partiallyActive={to !== '/'}
        onClick={() => isSublinkAvalable && subMenuSelected(!subMenuVisible)}
      >
        {children}
        {isSublinkAvalable && <SieArrow />}
      </Link>

      {isSublinkAvalable && subMenuVisible && (
        <MenuOverlay>
          <Holder>
            <StyledListItem home>
              <Link
                style={{ display: 'block' }}
                to={'#'}
                activeClassName="active"
                partiallyActive={to !== '/'}
                onClick={() => subMenuSelected(false)}
              >
                <ArrowLeft>
                  <SieArrow />
                </ArrowLeft>
                {t('mainmenu')}
              </Link>
            </StyledListItem>

            <HeaderItem>
              <Text size={20} black>
                {t('program')}
              </Text>
            </HeaderItem>

            {subitems &&
              subitems.map(({ name, link }, index) => (
                <StyledListItem>
                  <Link
                    to={`/${language || 'en'}${link}`}
                    activeClassName="active"
                    partiallyActive={to !== '/'}
                  >
                    {t(name)}
                  </Link>
                </StyledListItem>
              ))}
          </Holder>
        </MenuOverlay>
      )}
    </StyledListItem>
  )
}

const ArrowLeft = styled.div({
  display: 'inline-block',
  '& > svg': {
    transform: 'rotate(180deg)',
    marginBottom: '-2px',
  },
  width: '24px',
})

const Holder = styled.div({
  width: '100%',
  height: 'calc(100% - 80px)',
  gridColumn: '1/33',
})

const MenuOverlay = styled.div(({ theme: { grid, colors } }) => ({
  position: 'absolute',
  left: '100vw',
  top: 0,
  width: '100vw',
  display: 'grid',
  gridTemplateColumns: `repeat(${grid.columns},1fr)`,
  minHeight: '320px',
}))

const HeaderItem = styled.div({
  display: 'block',
  marginTop: '0.5rem',
  marginBottom: '2rem',
  width: '100%',
  padding: '9px 2.77778vw 9px calc(2.77778vw + 20px)',
  textAlign: 'left',
})

interface IStyledListItem {
  home?: boolean
}

const StyledListItem = styled.button<IStyledListItem>(
  ({ theme: { colors, fonts }, home }) => ({
    border: 0,
    outline: 0,
    display: 'block',
    width: '100%',
    position: 'relative',
    background: 'transparent',
    padding: `9px 2.77778vw 9px ${
      home ? 'calc(2.47778vw)' : 'calc(2.77778vw + 20px)'
    }`,
    '&:hover': {
      background: '#00183b',
    },
    a: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: '1.125rem',
      fontFamily: fonts.fontFamily,
      color: colors.link,
      textDecoration: 'none',
      textAlign: 'left',
      width: '100%',
      svg: {
        width: '18px',
        height: '18px',
      },
    },
  })
)
