import styled from '@emotion/styled'
import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { isPostEvent, isPostStream } from '../../../config'
import { MenuItem } from '../../atoms/menuItem'

export const HeaderMenu = ({
  language,
  hasParticipation,
}: {
  language: string
  hasParticipation: boolean | undefined
}) => {
  const { t } = useTranslation(`menuItems`)

  const phase3MenuPoints = [
    'index',
    'live',
    'agendaPhase3',
    'tech-sessions',
    'dialog',
    'informationmaterial',
  ]
  const phase3MenuPointsPostStream = [
    'index',
    'agendaPhase4',
    'tech-sessions',
    'dialog',
    'informationmaterial',
  ]
  const phase4MenuPoints = [
    'index',
    'agendaPhase4',
    'dialog',
    'informationmaterial',
  ]

  return (
    <StaticQuery
      query={graphql`
        query MainMenu {
          site {
            siteMetadata {
              mainMenu {
                active
                name
                home
                link
                hidePostEvent
              }
            }
          }
        }
      `}
      render={data => {
        const navData = [
          ...data.site.siteMetadata.mainMenu.filter(
            ({ active }: { active: boolean }) => active
          ),
        ].filter(({ hidePostEvent }) =>
          isPostEvent && hidePostEvent && isPostEvent() ? false : true
        )

        return (
          <HeaderMenuContainer>
            {navData.map(({ name, link, home }, index) => {
              if (isPostEvent() && !phase4MenuPoints.includes(name)) {
                return null
              }
              if (
                !isPostEvent() &&
                !isPostStream() &&
                !phase3MenuPoints.includes(name)
              ) {
                return null
              }

              if (
                !isPostEvent() &&
                isPostStream() &&
                !phase3MenuPointsPostStream.includes(name)
              ) {
                return null
              }

              return (
                <MenuItem to={link} key={index} home={home} language={language}>
                  {t(name)}
                </MenuItem>
              )
            })}
          </HeaderMenuContainer>
        )
      }}
    />
  )
}

const HeaderMenuContainer = styled.ul({
  display: 'inline-flex',
  listStyleType: 'none',
  padding: 0,
  margin: 0,
  height: '100%',
  pointerEvents: 'all',
})
