import styled from '@emotion/styled'
import { FeatureFlag, useFeatureFlags } from 'cuenect-web-core'
import Link from 'gatsby-link'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../atoms'
import { Logo } from '../../atoms/logo'
import { HeaderMenu } from '../../molecules/headerMenu'
import { LanguageChange } from '../../molecules/languageChange'
import { MobileMenu } from '../../molecules/mobileMenu'
import { TopMenu } from '../../molecules/topMenu'
import { languages } from './../../../config/languages'
import { useGlobalContext } from './../../../utility'
import { mq } from './../../../utility/breakpoint'
import { SieClose, SieMenu, SieUser } from './../../atoms'
interface HeaderProps {
  hideMenu?: boolean
  isTop?: boolean
  hasParticipation?: boolean
}

export const Header: React.FC<HeaderProps> = ({
  hideMenu,
  isTop,
  hasParticipation,
}) => {
  const { t } = useTranslation('appointments')
  const { phase2 } = useFeatureFlags()
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState<boolean>(false)

  const [, cLang] = (typeof window !== 'undefined' &&
    (document.location.pathname.match(/feedback\/[a-z]{2}/)
      ? document.location.pathname.match(/feedback\/([a-z]{2})/)
      : document.location.pathname.match(/\/(.{1,2})\/?/))) || ['en', 'en']

  const {
    dispatch: globalDispatch,
    state: { status: gobalStatus },
  } = useGlobalContext()

  React.useEffect(() => {
    globalDispatch({
      type: 'SET_STATUS',
      status: {
        // @ts-ignore
        mobileMenuOpen,
      },
    })
  }, [mobileMenuOpen])

  return (
    <HeaderContainer className={isTop ? 'noBackground' : ''}>
      <Logo uri={'http://www.siemens.com'} />
      <LanguageChangeContainer>
        {!hideMenu && (
          <TopMenu hasParticipation={hasParticipation} language={cLang} />
        )}
        {languages.languages.length > 1 && (
          <LanguageChange currentLanguage={cLang} />
        )}
      </LanguageChangeContainer>

      {!hideMenu && (
        <TopNavMobile>
          <TopMenu hasParticipation={hasParticipation} language={cLang} />
        </TopNavMobile>
      )}

      {!hideMenu && (
        <Hamburger className="hamburger">
          <Button
            asIcon
            icon={!mobileMenuOpen ? SieMenu : SieClose}
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          />
        </Hamburger>
      )}

      <HeaderMenuContainer>
        {!hideMenu && (
          <HeaderMenu hasParticipation={hasParticipation} language={cLang} />
        )}
      </HeaderMenuContainer>
      {!hideMenu && (
        <MobileMenu
          hasParticipation={hasParticipation}
          visible={mobileMenuOpen}
          currentLanguage={cLang}
        />
      )}
    </HeaderContainer>
  )
}

const HeaderContainer = styled.header(({ theme: { grid, colors } }) => ({
  pointerEvents: 'none',
  display: 'grid',
  gridTemplateColumns: `repeat(${grid.columns},1fr)`,
  position: 'absolute',
  top: 0,
  paddingTop:
    '40px' /*
  Disable transparent header for mobile
  '&.noBackground':{
    background: 'transparent',
  }, */,
  /*   background: colors.bodyBackground,
   */ zIndex: 100,
  [mq[3]]: {
    paddingBottom: '20px',

    background: 'transparent',
    position: 'relative',
    top: 'inherit',
  },
  '& > *': {
    pointerEvents: 'all',
  },
}))

const AppointmentsNav = styled(Link)(({ theme: { fonts, colors } }) => ({
  gridColumn: '30/33',
  svg: {
    height: '20px',
  },
  fontFamily: fonts.fontFamilyBlack,
  marginTop: '1px',
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  textDecoration: 'none',
  color: colors.link,
  letterSpacing: '-.5px',
  transition: 'color 0.25s',
  '&:hover': {
    borderColor: '#0cc',
  },
  '&.active': {
    color: '#0cc',
    borderColor: '#0cc',
  },
  [mq[3]]: {
    marginRight: '30px',
    borderBottom: '2px solid transparent',
    height: '23px',
    svg: {
      marginLeft: '10px',
      height: '1rem',
    },
  },
}))
const TopNavMobile = styled.div({
  gridColumn: '25/34',
  display: 'flex',
  justifyContent: 'flex-end',
  [mq[3]]: {
    display: 'none',
  },
})

const HeaderMenuContainer = styled.nav(() => ({
  height: '45px',
  gridRow: 2,
  gridColumn: '3/35',
  display: 'none',
  [mq[3]]: {
    display: 'block',
  },
}))

const Hamburger = styled.div(({ theme: { fonts, colors } }) => ({
  background: 'none',
  gridColumn: '34/35',
  zIndex: 1001,
  svg: {
    path: { fill: colors.menuItem },
  },
  [mq[3]]: {
    display: 'none',
  },
}))
const LanguageChangeContainer = styled.div(() => ({
  gridColumn: '20/35',
  display: 'none',

  justifyContent: 'flex-end',
  [mq[3]]: {
    display: 'flex',
  },
}))
